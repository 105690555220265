import { IpeUser, Permission } from '@models/models/models.types'
import { userLogger } from '@services/logger/logger.service'
import { SupabaseClient } from '@supabase/supabase-js'
import { supabaseBrowserClient } from '@utils/client'
import { pathTo } from '@utils/router'
import axios from 'axios'

const logger = userLogger
export async function forgotPassword(email: string) {
  try {
    await axios.post(pathTo().api().autenticacao().recuperarPalavraPasse(email))
  } catch (err) {
    console.error('err', err)
    throw new Error()
  }
}

export async function changePassword(password: string): Promise<void> {
  const {
    data: { user },
    error: userError,
  } = await supabaseBrowserClient().auth.updateUser({
    password: password,
  })

  if (userError) {
    console.error(userError.message)
    throw new Error(userError.message, { cause: userError })
  }

  const { error: profileError } = await supabaseBrowserClient()
    .from('profiles')
    .update({ palavra_passe_ativa: true })
    .eq('user_id', user!.id)

  if (profileError) {
    console.error(profileError.message)
    throw new Error(profileError.message, { cause: { name: 'Error', ...profileError } })
  }
}

export async function buildIpeUser(supabase: SupabaseClient) {
  const {
    data: { user: supabaseUser },
    error: getUserError,
  } = await supabase.auth.getUser()
  if (getUserError) {
    logger.error('failed to get user', getUserError)
    throw new Error('failed to get user', { cause: getUserError })
  }

  logger.debug(`getting profile and role from user=${supabaseUser?.email} email=${supabaseUser?.aud}`)

  const { data: profile, error } = await supabase.from('profiles').select('*').eq('user_id', supabaseUser?.id).single()
  if (error) {
    console.error(error)
    await supabase.auth.signOut()
  }

  const { data: user_roles, error: _error } = await supabase
    .from('user_roles')
    .select('*')
    .eq('user_id', supabaseUser?.id)

  if (_error) {
    console.error(_error)
    await supabase.auth.signOut()
  }
  const roles = user_roles?.map((ur) => ur.role)
  const { data, error: _err } = await supabase
    .from('role_permissions')
    .select('permission')
    .in('role', roles ?? [])
  const permissions = data?.map((item) => item.permission as Permission)

  const ipeUser = {
    ...supabaseUser,
    user_roles: user_roles,
    profile: profile,
    permissions: permissions,
  } as IpeUser

  logger.debug(`ipe user data`, ipeUser)
  return ipeUser
}
